<template>
  <div class="columns is-multiline is-marginless">
    <div class="column is-12">
      <h3 class="is-size-3 has-text-centered has-margin-bottom-150">
        {{ loading ? "Loading..." : step.label }}
      </h3>

      <step-tracker
        :total="enabledSteps.length"
        :active="stepNum"
        class="has-margin-bottom-200"
        @click="stepTo(!lastStep && $event < stepNum ? $event : stepNum)"
      />

      <loading v-if="loading" />

      <component
        :is="step.component"
        v-if="!loading"
        :payload="payload"
        @payload="onPayload"
        @next="stepTo()"
        @back="stepTo(stepNum - 1)"
        @reset="reset()"
      />
    </div>
  </div>
</template>

<script>
import currencies from "@src/data/currencies";
export default {
  name: "Onboarding",
  data() {
    return {
      loading: false,
      stepNum: null,
      steps: [
        {
          label: "Site details",
          component: () => import("./_siteDetailsStep"),
          beforeEnter: () => Promise.resolve(),
          isEnabled: () => {
            return true;
          }
        },
        {
          label: "Task details",
          component: () => import("./_taskDetailsStep"),
          beforeEnter: () => {
            return new Promise((resolve, reject) => {
              if (!this.payload.siteUrl) return reject();
              resolve();
            });
          },
          isEnabled: () => {
            return true;
          }
        },
        {
          label: "Create account",
          component: () => import("./_createAccountStep"),
          beforeEnter: () => {
            return new Promise((resolve, reject) => {
              if (!this.payload.siteUrl) return reject();
              resolve();
            });
          },
          isEnabled: () => {
            return true;
          }
        },
        {
          label: "Select a plan",
          component: () => import("@shared/cart/_selectPlanStep"),
          beforeEnter: () => {
            return new Promise((resolve, reject) => {
              if (!this.payload.siteUrl) return reject();
              if (!this.payload.userId) return reject();
              resolve();
            });
          },
          isEnabled: () => {
            return true;
          }
        },
        {
          label: "Payment details",
          component: () => import("./_paymentDetailsStep"),
          beforeEnter: () => {
            return new Promise((resolve, reject) => {
              if (!this.payload.siteUrl) return reject();
              if (!this.payload.userId) return reject();
              if (!this.payload.productTerm) return reject();
              resolve();
            });
          },
          isEnabled: () => {
            return !this.$_.isEmpty(this.payload.productTerm);
          }
        },
        {
          label: "Confirm order",
          component: () => import("@shared/cart/_confirmOrderStep"),
          beforeEnter: () => {
            return new Promise((resolve, reject) => {
              if (!this.payload.siteUrl) return reject();
              if (!this.payload.userId) return reject();
              if (!this.payload.productTerm) return reject();
              if (!this.payload.paymentMethod) return reject();
              resolve();
            });
          },
          isEnabled: () => {
            return !this.$_.isEmpty(this.payload.productTerm);
          }
        },
        {
          label: "Welcome aboard",
          component: () => import("./_confirmationStep"),
          beforeEnter: async () => {
            if (!this.payload.siteUrl) return Promise.reject();
            if (!this.payload.userId) return Promise.reject();
            try {
              const result = {};
              // 1. Create site
              result["siteId"] = (
                await this.$store.dispatch("sites/addSite", {
                  fqdn: this.payload.siteUrl.fqdn,
                  url: this.payload.siteUrl.url,
                  software: this.payload.siteSoftware,
                  userId: this.payload.userId
                })
              ).data.siteId;
              // 2. Place order (conditional)
              if (this.payload.productTerm && this.payload.paymentMethod) {
                result["order"] = (
                  await this.$store.dispatch("billing/orderBasket", {
                    siteId: result.siteId,
                    userId: this.payload.userId,
                    productId: this.payload.productTerm._productId,
                    productTermId: this.payload.productTerm._termId,
                    paymentMethodId: this.payload.paymentMethod.id,
                    couponId: this.payload.couponId,
                    currency: this.payload.currency
                  })
                ).data;
              }
              // 3. Create task (conditional)
              if (this.payload.taskTypePath && this.payload.taskDetails) {
                if (result.order) {
                  result["taskId"] = (
                    await this.$store.dispatch("tasks/addTask", {
                      siteId: result.siteId,
                      taskTypePath: this.payload.taskTypePath,
                      details: this.payload.taskDetails,
                      subject: this.payload.taskSubject
                    })
                  ).data.taskId;
                }
              }
              this.payload = this.$_.merge({}, this.payload, result);
              return Promise.resolve();
            } catch (error) {
              console.error(error);
              this.$toast.open({
                message:
                  error || "Oops, something went wrong – please try again.",
                type: "is-danger"
              });
              return Promise.reject();
            }
          },
          isEnabled: () => {
            return true;
          }
        }
      ],
      payload: {}
    };
  },
  computed: {
    enabledSteps() {
      return this.$_.filter(this.steps, step => {
        return step.isEnabled();
      });
    },
    step() {
      return this.enabledSteps[this.stepNum];
    },
    lastStep() {
      return this.stepNum + 1 === this.enabledSteps.length;
    },
    progress() {
      return ((this.stepNum + 1) / (this.enabledSteps.length + 1)) * 100;
    },
    user() {
      return this.$store.getters["user/user"](this.payload.userId);
    }
  },
  watch: {
    "step.component"() {
      this.loading = true;
      this.step.component().then(() => {
        this.step
          .beforeEnter()
          .then(() => {
            this.loading = false;
          })
          .catch(() => {
            this.loading = false;
            this.stepTo(this.stepNum - 1);
          });
      });
    },
    "$route.query.step"() {
      this.$set(this, "stepNum", parseInt(this.$route.query.step || 0));
    },
    payload: {
      handler(newPayload, oldPayload) {
        if (this.$_.isEmpty(oldPayload)) return;
        if (newPayload.siteId) return sessionStorage.removeItem("onboarding");
        try {
          sessionStorage.setItem(
            "onboarding",
            JSON.stringify(this.$_.omit(newPayload, ["userId", "termsAgreed"]))
          );
        } catch {
          // Fail silently (not critical)
        }
      },
      deep: true
    }
  },
  created() {
    this.reset();
    this.stepTo(parseInt(this.$route.query.step) || 0); // Set initial step
  },

  methods: {
    onPayload(event) {
      Object.keys(event).forEach(key => {
        this.payload[key] = event[key];
      });
    },
    stepTo(stepNum = this.stepNum + 1) {
      if (this.loading) return;
      if (stepNum <= this.enabledSteps.length - 1) {
        this.stepNum = stepNum;
        this.$router.push({
          query: this.$_.merge({}, this.$route.query, { step: stepNum })
        });
        this.$gtm.trackEvent({
          event: "app.event",
          category: "Onboarding",
          action: "Step to",
          label: this.step.label
        });
      } else {
        this.stepTo(0);
      }
    },
    reset() {
      let progress;
      try {
        progress = JSON.parse(sessionStorage.getItem("onboarding"));
      } catch {
        // No progress or error passing malformed sessionStorage value
        sessionStorage.removeItem("onboarding");
        progress = {};
      }

      let currency = this.$_.get(this.$route.query, "currency");
      let couponId = this.$_.get(this.$route.query, "couponId", "");
      currency = this.$_.keys(currencies).includes(currency) ? currency : null;
      this.$set(
        this,
        "payload",
        this.$_.merge(
          {
            couponId,
            creditBalance: null,
            currency,
            paymentMethod: null,
            productTerm: null,
            siteSoftware: "wordpress",
            siteUrl: null,
            taskDetails: null,
            taskSubject: null,
            taskTypePath: null,
            termsAgreed: false,
            userId: this.$store.getters["auth/userId"]() || null
          },
          progress
        )
      );
    }
  }
};
</script>
